import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import { IoPricetag, IoTime } from "react-icons/io5";
import Seo from "../components/seo";

const Delivery = () => {
    return (
        <Layout>
            <Seo
                title="Delivery - BIKESAFE"
                description="At Bike Safe we will always endeavor to get your order to you on time, however if the item is out of stock for a prolonged period, one of our customer service reps will contact you via e-mail or phone to update you on the progress of your order."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5">
                            Free delivery on all orders
                        </h1>
                        <p className="flow-text">At Bike Safe we will always endeavor to get your order to you on time, however if the item is out of stock for a prolonged period, one of our customer service reps will contact you via e-mail or phone to update you on the progress of your order.</p>
                        <h2 className="mt-5 mb-5"><IoPricetag /> How much will it cost?</h2>
                        <p className="flow-text">
                            Its free delivery to Ireland and Northern Ireland! A small surcharge will apply to remote locations.</p>
                        <h2 className="mt-5 mb-5"><IoTime /> How long will it take?</h2>
                        <p className="flow-text">Delivery times vary depending on the product. Estimated delivery times are shown where possible with each product, but exact delivery times can not be confirmed.</p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Delivery;